import React from 'react'

import { AlertIcon, Inline, SquareButton, XIcon } from '@te-digi/styleguide'

import { Playground } from '../../components/Playground'
import { Content } from '../../components/Content'
import { Section } from '../../components/Section'
import ButtonHTMLExample from '../../examples/html/ButtonHTMLExample'

const onButtonClick = () => alert('Click!')

// HUOM! lead-teksti määritellään pages.ts -tiedostossa
const Page = () => (
  <Content
    heading="SquareButton"
    components={[{ component: SquareButton, restElement: true }]}
  >
    <Section>
      <Playground>
        <Inline
          gap="md"
          wrap="wrap"
        >
          <SquareButton onClick={onButtonClick}>Teksti</SquareButton>
          <SquareButton
            badgeIcon={<AlertIcon />}
            iconLeft={<XIcon />}
            onClick={onButtonClick}
          >
            Teksti
          </SquareButton>
          <SquareButton
            disabled
            onClick={onButtonClick}
          >
            Teksti
          </SquareButton>
          <SquareButton
            onClick={onButtonClick}
            size="sm"
          >
            Teksti
          </SquareButton>
          <SquareButton
            badgeIcon={<AlertIcon />}
            iconLeft={<XIcon />}
            onClick={onButtonClick}
            size="sm"
          >
            Teksti
          </SquareButton>
          <SquareButton
            onClick={onButtonClick}
            size="xs"
          >
            Teksti
          </SquareButton>
          <SquareButton
            badgeIcon={<AlertIcon />}
            iconLeft={<XIcon />}
            onClick={onButtonClick}
            size="xs"
          >
            Teksti
          </SquareButton>
        </Inline>
      </Playground>
      <Playground format="html">
        <div className="flex flex-wrap mt-n-md">
          <div className="mt-md mr-md">
            <ButtonHTMLExample shape="square">Teksti</ButtonHTMLExample>
          </div>
          <div className="mt-md mr-md">
            <ButtonHTMLExample
              disabled
              shape="square"
            >
              Teksti
            </ButtonHTMLExample>
          </div>
          <div className="mt-md mr-md">
            <ButtonHTMLExample
              shape="square"
              size="sm"
            >
              Teksti
            </ButtonHTMLExample>
          </div>
          <div className="mt-md">
            <ButtonHTMLExample
              shape="square"
              size="xs"
            >
              Teksti
            </ButtonHTMLExample>
          </div>
        </div>
      </Playground>
    </Section>
  </Content>
)

export default Page
